'use client';
import React from 'react';
import Image from 'next/image';
import HIHButton from '@/components/base/Button';
import { useRouter } from 'next/navigation';

export default function NotFound() {
  const router = useRouter();
  return (
    <div className="container-content py-7xl lg:py-9xl ">
      <div className="px-sm lg:flex lg:gap-9xl lg:justify-center">
        <div className=" lg:order-2 flex justify-center">
          <Image
            src="/images/404.png"
            alt="Fehler 410"
            width={510}
            height={494}
            className="lg:min-w-[510px]"
          />
        </div>

        <div className="  flex flex-col lg:justify-center text-center lg:text-left lg:px-0">
          <h2 className="mt-5xl font-display text-headline-md lg:text-display-sm uppercase ">
            Oh no, hier gehts leider nicht weiter
          </h2>
          <p className=" mt-xl lg:mt-3xl text-body-lg lg:text-title-lg text-sys/surface/on-surface-variant lg:max-w-[560px]">
            Tut uns total Leid. Die Seite existiert nicht mehr. Als Trostpflaster zeigen
            wir dir eines unserer liebsten Hamburg-Bilder.
          </p>
          <div className=" inline-flex mt-5xl lg:mt-6xl gap-x-lg justify-center lg:justify-start">
            <HIHButton
              onClick={router.back}
              title="zurück"
              variant="secondary"
              showArrowBefore
              size="sm"
            />
            <HIHButton link="/" title="Zur Startseite" variant="outline" />
          </div>
        </div>
      </div>
    </div>
  );
}
